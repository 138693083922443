@use '../../foundation/variables' as v;

.p-page {
  &__title {
    @include v.flexbox(start, center, no-wrap);
    margin-bottom: 4em;
    @include v.sp {
      flex-flow: column;
      align-items: flex-start;
    }
    &__large {
      font-size: v.$scale-up1;
      text-transform: uppercase;
      line-height: 1;
    }
    &__small {
      letter-spacing: 0.025em;
      display: block;
      font-size: v.$scale-up5;
      font-weight: bold;
      color: #827358;
      margin-left: 1em;
      @include v.sp {
        margin-left: 0;
      }
    }
  }
  &__thumb {
    margin-bottom: 1.5em;
  }
  &__place {
    margin-bottom: 0.25em;
  }
  &__single-title {
    margin-bottom: 0.75em;
    font-size: v.$scale-up3;
    line-height: 1.3;
  }
  &__main {
    margin-bottom: 5em;
    @include v.sp {
      margin-bottom: 2.5em;
    }
    h2 {
      @include v.default-font;
      font-size: v.$scale-up4;
      font-weight: bold;
      border-bottom: 1px solid v.$primary;
      padding: 0.5em 0;
      margin-bottom: 1em;
    }
    h3 {
      font-size: v.$scale-up5;
      background-color: v.$primary;
      color: v.$beige-light;
      border-radius: 4px;
      padding: 0.5em 1em;
      margin-bottom: 1em;
    }
    h4 {
      font-size: v.$scale-up6;
      margin-bottom: 1em;
    }
    img {
      border-radius: 4px !important;
      margin-bottom: 1.5em;
      width: 100%;
    }
    p {
      margin-bottom: 1.5em;
      text-align: justify;
      word-break: break-all;
      white-space: pre-wrap;
      a {
        position: relative;
        padding-left: 1em;
        font-weight: bold;
        @include v.animation(opacity);
        @media(hover: hover) {
          &:hover {
            opacity: 0.7;
          }
        }
        &::before {
          content: "";
          margin: auto;
          vertical-align: middle;
          width: 8px;
          height: 8px;
          border-top: 1px solid v.$primary-dark;
          border-right: 1px solid v.$primary-dark;
          transform: rotate(45deg);
          position: absolute;
          top: 4px;
          left: 0;
        }
      }
    }
    ul {
      list-style: disc;
      padding-left: 1em;
      margin-bottom: 1.5em;
      li {
        margin-bottom: 0.5em;
      }
    }
    li {
      a {
        font-weight: bold;
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      margin-bottom: 1.5em;
      tr {
        width: 100%;
        margin-bottom: 1em;
        border-bottom: 1px solid rgba($color: #392709, $alpha: 0.3);
        &:first-child {
          border-top: 1px solid rgba($color: #392709, $alpha: 0.3);
        }
      }
      th {
        width: 30%;
        text-align: left;
        padding: 1em;
        @include v.sp {
          padding-bottom: 0;
          width: 100%;
          display: block;
        }
      }
      td {
        padding: 1em;
        width: 70%;
        @include v.sp {
          width: 100%;
          display: block;
        }
      }
    }
    section {
      padding: 1.5em 0 3em;
    }
  }
  .page-index {
      list-style: decimal;
      margin: 2.5em 0;
      padding: 2em 1.5em 1.5em 3em;
      border: 1px solid v.$primary;
      position: relative;
      &::before {
        @include v.belleza;
        content: 'CHAPTER';
        display: block;
        padding: 0 0.5em;
        background-color: v.$beige-light;
        position: absolute;
        font-size: v.$scale-up5;
        color: v.$primary;
        top: -16px;
        left: 1em;
      }
      li {
        margin-bottom: 0.5em;
        &:last-child {
          margin-bottom: 0;
        }
        >a {
          font-weight: bold;
          @include v.animation(color);
          @media (hover: hover) {
            &:hover {
              color: v.$primary;
            }
          }
        }
      }
    }
  .directors-voice {
    background-color: rgba($color: v.$yellow, $alpha: 0.15);
    padding: 2em;
    margin-bottom: 1.5em;
    h3 {
      color: v.$primary-light;
      background-color: unset;
      text-align: center;
      font-size: v.$scale-up4;
      padding: 0;
      margin-bottom: 0.5em;
      @include v.belleza();
    }
    &-editor {
      margin-bottom: 0;
      text-align: right;
      @include v.belleza;
      color: v.$primary;
      &::before {
        content: 'Text：';
      }
    }
  }
  .mission-h2 {
    text-align: center;
    @include v.belleza();
    border-bottom: none;
    margin-bottom: 0;
    font-size: v.$scale-up5;
    color: v.$primary-light;
  }
  .mission-h3 {
    background-color: transparent;
    color: v.$primary-dark;
    font-weight: bold;
    font-size: v.$scale-up4;
    text-align: center;
    padding: 0
  }
  &__tags {
    @include v.flexbox(centert, start, wrap);
    margin-bottom: 1em;
  }
  &__detail {
    margin-bottom: 1em;
  }
  &__text {
    font-weight: bold;
    font-size: v.$scale-up4;
    margin-top: 0.1em;
    @include v.three-point-reader(3);
  }
  &__place {
    font-weight: bold;
  }
  &__category {
    @include v.animation(background-color);
    &:hover {
      background-color: v.$primary-dark;
    }
  }
  &__sns {
    margin-top: 5em;
    p {
      text-align: center;
      color: v.$primary;
      font-size: v.$scale-up6;
      font-weight: bold;
      letter-spacing: 0.05em;
      margin-bottom: 0.75em;
    }
    &__links {
      @include v.flexbox(center, center, nowrap);
    }
    &__link {
      @include v.flexbox(center, center, nowrap);
      border: 1px solid rgba($color: v.$primary-dark, $alpha: 0.7);
      border-radius: 50%;
      width: 54px;
      height: 54px;
      margin-right: 1.5em;
      @include v.button-hover(54px, 54px, v.$primary-dark);
      &:last-child {
        margin-right: 0;
      }
      svg {
        @include v.animation(color);
      }
      @media(hover: hover) {
        &:hover {
          svg {
            color: v.$beige-light;
          }
        }
      }
    }
  }
  &__sns-content {
    z-index: 1;
  }
  &__sns-tw {
    svg {
      width: 20px;
      height: 20px;
      color: v.$primary-dark;
    }
  }
  &__sns-fb {
    svg {
      width: 19px;
      height: 19px;
      color: v.$primary-dark;
    }
  }
}

.p-sitemap {
  margin-top: 5em;
  &__title {
    @include v.belleza;
    color: v.$beige-light;
    padding: 0.25em 0.5em;
    margin-bottom: 0.5em;
    border-radius: 5px;
    font-size: v.$scale-up4;
    text-transform: uppercase;
    background-color: v.$primary;
  }
  &__list {
    margin: 0 0.75em;
    margin-bottom: 2.5em;
    @include v.flexbox(start, start, wrap);
    @include v.sp {
      flex-flow: column;
    }
  }
  &__item {
    padding: 0.25em 0;
    margin-right: 1em;
    &::after {
      content: '/';
      color: v.$primary;
      margin-left: 1em;
      font-size: v.$scale-up6;
      @include v.sp {
        display: none;
      }
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    a {
      font-size: v.$scale-up5;
      letter-spacing: 0.01em;
      @include v.animation(color);
      @media(hover: hover) {
        &:hover {
          color: v.$primary;
        }
      }
    }
  }
}