@use '../foundation/variables' as v;

.l-inner {
  margin: 0 auto;
  padding: 0 5%;
}

.l-page {
  max-width: 800px;
  width: 100%;
  margin: 5em auto 10em;
  @include v.sp {
    margin: 2.5em auto 5em;
  }
}

.l-info {
  background-color: v.$beige;
}

.l-archive {
  margin: 5em auto 10em;
  @include v.sp {
    margin: 2.5em auto 5em;
  }
}

.l-col2 {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 6%;
  justify-content: center;
  @include v.tb {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: unset;
  }
}

.l-blog {
  @include v.tb {
    margin-bottom: 3em;
  }
}

.l-categories {
  padding: 0;
}

.l-contact {
  padding: 5em 0;
  background-image: url(/wp-content/themes/banshaku/images/contact.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  @include v.tb {
    padding: 3em 0;
  }
  @include v.sp {
    background-image: url(/wp-content/themes/banshaku/images/contact-sp.jpg);
  }
}

.l-footer {
  padding: 6em 0;
  background-color: v.$primary-dark;
}

.l-mv {
  padding: 0 0 3em;
  @include v.sp {
    padding-bottom: 3em;
  }
}

.l-header {
  padding: 0 5%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get(v.$z, header);
  width: 100%;
  height: 10em;
  background-color: v.$beige-light;
  @include v.sp {
    height: 8em;
  }
  @include v.animation(height);
  &--downsize {
    height: 4.5em;
  }
}

.l-main {
  padding-top: 10em;
  @include v.sp {
    padding-top: 8em;
  }
}