@use '../../foundation/variables' as v;

.p-mv {
  .swiper-slide {
    width: 700px;
    @include v.sp {
      width: 90%;
    }
  }
  &__post {
    display: block;
    position: relative;
    &__thumb {
      position: relative;
      border-radius: 8px;
      &::after {
        content: '';
        background: linear-gradient(transparent 50%, rgba($color: black, $alpha: 0.6) 100%);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @include v.sp {
          background: unset;
        }
      }
    }
  }
  &__text-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1.5em;
    font-size: v.$scale-up5;
    width: 100%;
    @include v.sp {
      padding: 0 0.5em 0.5em 0.5em;
      position: initial;
      bottom: unset;
      left: unset;
    }
  }
  &__detail, &__text, &__time {
    color: v.$beige-light;
  }
  &__detail {
    @include v.flexbox(between, center, nowrap);
    margin: 0.5em 0;
  }
  &__place {
    font-size: 1rem;
    @include v.sp {
      color: v.$primary;
    }
    p, svg {
      color: v.$beige-light;
      @include v.sp {
        color: v.$primary;
      }
    }
  }
  &__time {
    font-size: v.$scale-down1;
    @include v.sp {
      color: v.$primary;
    }
  }
  &__category {
    background-color: rgba($color: v.$primary-light, $alpha: 0.9);
    font-size: v.$scale-down1;
  }
  &__text {
    margin-top: 0.25em;
    line-height: 1.4;
    font-size: v.$scale-up4;
    @include v.three-point-reader(2);
    @include v.sp {
      color: v.$primary-dark;
    }
  }
  &__navigation {
    margin-top: 3em;
    @include v.flexbox(center, center, nowrap);
    @include v.sp {
      margin-top: 1em;
    }
  }
  &__pagination {
    @include v.flexbox(center, center, nowrap);
    .swiper-pagination-bullet {
      margin: 0.25em;
      display: block;
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      counter-increment: index;
      cursor: pointer;
      @include v.flexbox(center, center, nowrap);
      @include v.animation();
      background-color: transparent;
      @media (hover: hover) {
        &:hover {
          background-color: rgba($color: v.$primary-dark, $alpha: 0.3);
        }
      }
      &:nth-child(n) {
        &::before {
          content: counter(index);
        }
      }
      &.swiper-pagination-bullet-active {
        border: 1px solid v.$primary-dark;
        background-color: v.$primary-dark;
        color: v.$beige-light;
      }
    }
  }
  &__button-prev, &__button-next {
    @include v.flexbox(center, center, nowrap);
    padding: 0.5em;
    span {
      width: 1.5em;
      height: 1.5em;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      cursor: pointer;
    }
    @include v.animation();
  }
  &__button-prev {
    @media (hover: hover) {
      &:hover {
        transform: translateX(-5px);
      }
    }
    span {
      border-bottom: 1px solid v.$primary-dark;
      border-left: 1px solid v.$primary-dark;
    }
  }
  &__button-next {
    @media (hover: hover) {
      &:hover {
        transform: translateX(5px);
      }
    }
    span {
      border-top: 1px solid v.$primary-dark;
      border-right: 1px solid v.$primary-dark;
    }
  }
}