// COLOR
$primary-dark: #3c2602;
$primary: #827358;
$primary-light: #be924a;
$orange: #eb9300;
$yellow: #ffd215;
$red: #cb3b28;
$blue: #42617e;
$beige-light: #fff8eb;
$beige: #fff1d9;

// Z-INDEX
$z: (
  loading: 50,
  menu: 40,
  button: 30,
  header: 10,
);
// FONT SIZE
$scale-up1: 3.05rem;
$scale-up2: 2.44rem;
$scale-up3: 1.953rem;
$scale-up4: 1.563rem;
$scale-up5: 1.25rem;
$scale-up6: 1.125rem;
$scale-down1: 0.889rem;
$scale-down2: 0.8rem;
$scale-down3: 0.632rem;

// BREAK POINTS
@mixin pc {
  @media (min-width: 1080.2px) {
    @content;
  }
}
@mixin tb {
  @media (max-width: 1080px) {
    @content;
  }
}
@mixin sp {
  @media (max-width: 768px) {
    @content;
  }
}

// FLEX BOX
@mixin flexbox($justify: start, $align: start, $wrap: nowrap) {
  display: flex;

  @if $justify == "start" {
    justify-content: flex-start;
  } @else if $justify == "center" {
    justify-content: center;
  } @else if $justify == "end" {
    justify-content: flex-end;
  } @else if $justify == "around" {
    justify-content: space-around;
  } @else if $justify == "between" {
    justify-content: space-between;
  }

  @if $align == "start" {
    align-items: flex-start;
  } @else if $align == "center" {
    align-items: center;
  } @else if $align == "end" {
    align-items: flex-end;
  } @else if $align == "stretch" {
    align-items: stretch;
  }

  @if $wrap == "nowrap" {
    flex-wrap: nowrap;
  } @else if $wrap == "wrap" {
    flex-wrap: wrap;
  }
}

// FONT
@mixin default-font {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium",
    "游ゴシック", "Yu Gothic", sans-serif;
}
@mixin belleza {
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
}
@mixin letter-space {
  letter-spacing: 0.025em;
}
@mixin adjust-letter-space {
  font-feature-settings: "palt";
}

@mixin three-point-reader($line: 2) {
  -webkit-line-clamp: $line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// ANIMATION
@mixin animation($property: all) {
  transition-property: $property;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}

@mixin button-hover($w: 50px, $h: 50px, $color: $primary-light) {
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: $h;
    left: 50%;
    width: $w;
    height: $h;
    transform: translate(-50%, 0px);
    background-color: $color;
    z-index: 0;
    @include animation(top);
  }
  @media (hover: hover) {
    &:hover {
      &::after {
        top: 0px;
      }
    }
  }
}

@mixin arrow($size: 24px, $bgColor: $primary-dark) {
  background-color: $primary-dark;
  border-radius: 50%;
  width: $size;
  height: $size;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  &::after {
    margin: auto;
    content: '';
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-top: 1px solid $beige-light;
    border-right: 1px solid $beige-light;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40%;
  }
}