@use '../foundation/variables' as v;

html {
  font-size: 16px;
  background-color: v.$beige-light;
  @include v.sp {
    font-size: 14px;
  }
}

body {
  @include v.default-font;
  color: v.$primary-dark;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  line-height: 1.65;
}

a {
  color: v.$primary-dark;
  text-decoration: none;
}

em {
  font-style: normal;
  color: v.$red;
}

mark {
  background:linear-gradient(transparent 60%, v.$yellow 0%);
}

figcaption {
  text-align: center;
  font-size: v.$scale-down1;
}

section {
  padding: 5em 0;
  @include v.sp {
    padding: 3em 0;
  }
}

svg {
  display: block;
  vertical-align: middle;
}

input[type="text"],
input[type="email"],
textarea {
  font-size: 18px;
  padding: 0.5em 0.75em;
  width: 100%;
  height: 70px;
  border-radius: 8px;
  border: none;
  background-color: v.$beige-light;
  border: 1px solid v.$primary-light;
}

textarea {
  height: 300px;
  resize: vertical;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
}