@use '../../foundation/variables' as v;

.p-archive {
  &__title {
    @include v.flexbox(start, center, no-wrap);
    margin-bottom: 4em;
    @include v.sp {
      flex-flow: column;
      align-items: flex-start;
      margin-bottom: 3em;
    }
    &__large {
      font-size: v.$scale-up1;
      text-transform: uppercase;
      line-height: 1.2;
      @include v.sp {
        font-size: v.$scale-up2;
      }
    }
    &__small {
      letter-spacing: 0.025em;
      display: block;
      font-size: v.$scale-up5;
      font-weight: bold;
      color: #827358;
      margin-left: 1em;
      @include v.sp {
        margin-left: 0;
        font-size: v.$scale-up6;
      }
    }
  }
  &__list {
    @include v.flexbox(between, start, wrap);
    @include v.tb {
      flex-flow: column;
      justify-content: flex-start;
    }
  }
  &__item {
    width: 48%;
    margin-bottom: 2.5em;
    @include v.tb {
      width: 100%;
    }
  }
  &__detail {
    @include v.flexbox(between, center, nowrap);
    margin: 0.75em 0 0.5em;
  }
  &__text {
    font-weight: bold;
    font-size: v.$scale-up5;
    margin-top: 0.1em;
    @include v.three-point-reader(3);
    @include v.sp {
      font-size: v.$scale-up5;
    }
  }
  &__place {
    font-weight: bold;
  }
  &__category, &__time {
    @include v.sp {
      font-size: v.$scale-down1;
    }
  }
  &__cats {
    @include v.flexbox(start, start, wrap);
    margin-bottom: 5em;
    @include v.sp {
      margin-bottom: 3em;
      flex-flow: column;
    }
    &__item {
      @include v.flexbox(center, center, nowrap);
      border: 1px solid v.$primary;
      border-radius: 5px;
      font-size: v.$scale-up6;
      font-weight: normal;
      color: v.$primary;
      width: 200px;
      height: 56px;
      line-height: 1.2;
      text-align: center;
      margin-right: 1em;
      margin-bottom: 1em;
      @include v.animation();
      position: relative;
      @include v.button-hover(200px, 56px, v.$primary);
      @include v.sp {
        width: 100%;
        height: 40px;
        margin-bottom: 0.5em;
        margin-right: 0;
        @include v.button-hover(100%, 56px, v.$primary);
      }
      @media(hover: hover) {
        &:hover {
          color: v.$beige-light;
        }
      }
      &__content {
        z-index: 1;
      }
    }
    &--active {
      background-color: v.$primary;
      color: v.$beige-light;
    }
  }
}

.p-search {
  &__title {
    font-size: v.$scale-up3;
  }
}