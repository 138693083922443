@use '../../foundation/variables' as v;

.p-info {
  &__head {
    @include v.flexbox(between, center, no-wrap);
    margin-bottom: 2.5em;
  }
  &__title {
    @include v.flexbox(start, center, no-wrap);
    @include v.sp {
      flex-flow: column;
      align-items: flex-start;
    }
  }
  &__list-container {
    padding: 0 5% 0;
  }
  &__item {
    width: 340px;
    @include v.sp {
      width: 80%;
    }
  }
  &__link {
    display: inline-block;
    position: relative;
    margin-top: 2.5em;
    padding-right: 3em;
    padding-top: 1em;
    padding-bottom: 1em;
    @media (hover: hover) {
      &:hover {
        .p-info__link-arrow {
          right: -5px;
        }
      }
    }
    @include v.sp {
      margin-top: 1em;
    }
  }
  &__link-arrow {
    @include v.arrow();
    @include v.animation(right);
  }
  &__detail {
    margin: 0.75em 0 0.5em;
  }
  &__category,
  &__time {
    font-size: v.$scale-down1;
  }
  &__text {
    margin-top: 0.15em;
  }
  &__navigation {
    @include v.flexbox(center, center, nowrap);
  }
  &__button-prev, &__button-next {
    cursor: pointer;
    margin: 0.5em;
    padding: 0.5em;
    @include v.flexbox(center, center, nowrap);
    @include v.animation(transform);
    @include v.tb {
      margin: 0.25em;
      padding: 0.25em;
    }
    span {
      width: 1.5em;
      height: 1.5em;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      display: block;
    }
    &.swiper-button-disabled {
      opacity: 0.2;
      @media (hover: hover) {
        &:hover {
          opacity: 0.2;
          transform: translateX(0px);
        }
      }
    }
  }
  &__button-prev {
    @media (hover: hover) {
      &:hover {
        transform: translateX(-5px);
      }
    }
    span {
      border-bottom: 1px solid v.$primary-dark;
      border-left: 1px solid v.$primary-dark;
    }
  }
  &__button-next {
    @media (hover: hover) {
      &:hover {
        transform: translateX(5px);
      }
    }
    span {
      border-top: 1px solid v.$primary-dark;
      border-right: 1px solid v.$primary-dark;
    }
  }
  &__place {
    font-size: v.$scale-down1;
    font-weight: bold;
  }
}
