@use '../../foundation/variables' as v;

.fixed {
  overflow: hidden;
}

.position-right {
  text-align: right;
}

.fit-img {
  display: block;
  position: relative;
  padding-bottom: 65%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
    position: absolute;
    left: 0;
    top: 0;
  }
}

.lazyload {
  opacity: 0;
  @include v.animation(opacity);
  &.loaded {
    opacity: 1;
  }
}

.large {
  font-size: v.$scale-up6;
}

.center {
  text-align: center;
}

.mb-m {
  margin-bottom: 1.5em;
}

.belleza {
  @include v.belleza();
}

.narrow {
  max-width: 960px;
  margin: 0 auto;
}

// WP PAGENABI
.wp-pagenavi {
  margin: 2.5em 5%;
  text-align: center;
  @include v.flexbox(center, start, nowrap);
  @include v.sp {
    margin: 0 5%;
  }
  span.pages,
  span.extend,
  .nextpostslink,
  .previouspostslink {
    display: none;
  }
  span.current,
  .smaller,
  .larger,
  .first,
  .last {
    @include v.flexbox(center, center, nowrap);
    border: none;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    font-size: v.$scale-up6;
    margin: 0.25em;
  }
  span.current {
    background-color: v.$primary-dark;
    color: v.$beige-light;
    font-weight: normal;
  }
  .smaller,
  .larger,
  .first,
  .last {
    @include v.animation();
    &:hover {
      background-color: rgba($color: v.$primary-dark, $alpha: 0.1);
    }
  }
}

//  CONTACT FORM7
.wpcf7 {
  .acceptance {
    margin-top: 2em;
    @include v.flexbox(center, center, nowrap);
    flex-flow: column;
    background-color: v.$beige;
    padding: 2em;
  }
  p {
    margin-bottom: 0.5em;
  }
  .submit {
    margin: 2em 0;
    input[type="submit"] {
      max-width: 280px;
      width: 100%;
      margin: 0 auto 1em;
      padding: 1em;
      text-align: center;
      display: block;
      color: v.$beige-light;
      background-color: v.$primary-dark;
      border: 1px solid v.$primary-dark;
      border-radius: 50px;
      appearance: none;
      cursor: pointer;
      @include v.animation(opacity);
      @media(hover: hover) {
        &:hover {
          opacity: 0.7;
        }
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

// WP BLOCK
.wp-block-image {
  margin-bottom: 1.5em;
  img {
    margin: 0 auto 0.5em;
  }
  figcaption {
    display: block !important;
  }
}

// LIQUID BALOON
.liquid-speech-balloon-wrap {
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.liquid-speech-balloon-bubble {
  @include v.flexbox(center, start, nowrap);
  background-color: v.$beige;
  padding: 2em 3em 4em;
  &::before, &::after {
    display: none !important;
  }
  @include v.sp {
    padding: 1.5em;
  }
  .liquid-speech-balloon-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    @include v.sp {
      width: 64px;
      height: 64px;
    }
  }
  .liquid-speech-balloon-text {
    position: relative;
    z-index: 1;
    border-radius: 0px;
    padding: 0;
    width: 100%;
    border: none;
  }
}

.liquid-speech-balloon-text {
  background-color: unset;
  border: 1px solid v.$primary;
  padding: 1.5em;
  p {
    margin-bottom: 0 !important;
  }
}

.liquid-speech-balloon-avatar {
  background-size: cover;
  background-position: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  @include v.sp {
    width: 64px;
    height: 64px;
  }
  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    left: 0;
    top: 85px;
    font-size: 0.75rem;
    line-height: 1.25;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.01em;
    @include v.sp {
      top: 70px;
    }
  }
}

.liquid-speech-balloon-right {
  .liquid-speech-balloon-text {
    margin: 0 2em 0 0;
  }
}
.liquid-speech-balloon-text {
  margin: 0 0 0 2em;
}

.liquid-speech-balloon-text {
  strong {
    margin-bottom: 0.5em;
    span {
      color: #42617E !important;
    }
  }
}

.liquid-speech-balloon-00 {
  .liquid-speech-balloon-text {
    strong {
      margin-bottom: 0.5em;
      span {
        color: v.$primary-light !important;
      }
    }
  }
}

.liquid-speech-balloon-wrap:not(.liquid-speech-balloon-vertical) .liquid-speech-balloon-arrow {
  border-right: 10px solid v.$primary;
}

.liquid-speech-balloon-wrap.liquid-speech-balloon-right .liquid-speech-balloon-arrow {
  border-left: 10px solid v.$primary;
  border-right: 7px solid transparent;
}

.wpcf7-not-valid-tip {
  margin-top: 0.5em;
}

.wp-block-embed-youtube {
  iframe {
    height: 460px;
    @include v.sp {
      height: 200px;
    }
  }
}

.wp-block-embed iframe {
  width: 100%;
}

.wp-embed {
  background-color: transparent;
}

.p-sp-search {
  display: none;
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
  z-index: map-get(v.$z, button);
  @include v.sp {
    display: flex;
    width: 42px;
    height: 42px;
  }
}

.gmap {
  width: 100%;
}

.instagram-media,
.twitter-tweet {
  margin: 0px auto 1em !important;
}