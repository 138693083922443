@charset "UTF-8";
html {
  font-size: 16px;
  background-color: #fff8eb;
}
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  color: #3c2602;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  line-height: 1.65;
}

a {
  color: #3c2602;
  text-decoration: none;
}

em {
  font-style: normal;
  color: #cb3b28;
}

mark {
  background: linear-gradient(transparent 60%, #ffd215 0%);
}

figcaption {
  text-align: center;
  font-size: 0.889rem;
}

section {
  padding: 5em 0;
}
@media (max-width: 768px) {
  section {
    padding: 3em 0;
  }
}

svg {
  display: block;
  vertical-align: middle;
}

input[type=text],
input[type=email],
textarea {
  font-size: 18px;
  padding: 0.5em 0.75em;
  width: 100%;
  height: 70px;
  border-radius: 8px;
  border: none;
  background-color: #fff8eb;
  border: 1px solid #be924a;
}

textarea {
  height: 300px;
  resize: vertical;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
}

.l-inner {
  margin: 0 auto;
  padding: 0 5%;
}

.l-page {
  max-width: 800px;
  width: 100%;
  margin: 5em auto 10em;
}
@media (max-width: 768px) {
  .l-page {
    margin: 2.5em auto 5em;
  }
}

.l-info {
  background-color: #fff1d9;
}

.l-archive {
  margin: 5em auto 10em;
}
@media (max-width: 768px) {
  .l-archive {
    margin: 2.5em auto 5em;
  }
}

.l-col2 {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 6%;
  justify-content: center;
}
@media (max-width: 1080px) {
  .l-col2 {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: unset;
  }
}

@media (max-width: 1080px) {
  .l-blog {
    margin-bottom: 3em;
  }
}

.l-categories {
  padding: 0;
}

.l-contact {
  padding: 5em 0;
  background-image: url(/wp-content/themes/banshaku/images/contact.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
@media (max-width: 1080px) {
  .l-contact {
    padding: 3em 0;
  }
}
@media (max-width: 768px) {
  .l-contact {
    background-image: url(/wp-content/themes/banshaku/images/contact-sp.jpg);
  }
}

.l-footer {
  padding: 6em 0;
  background-color: #3c2602;
}

.l-mv {
  padding: 0 0 3em;
}
@media (max-width: 768px) {
  .l-mv {
    padding-bottom: 3em;
  }
}

.l-header {
  padding: 0 5%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 10em;
  background-color: #fff8eb;
  transition-property: height;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (max-width: 768px) {
  .l-header {
    height: 8em;
  }
}
.l-header--downsize {
  height: 4.5em;
}

.l-main {
  padding-top: 10em;
}
@media (max-width: 768px) {
  .l-main {
    padding-top: 8em;
  }
}

.c-top-h2 h2 {
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  font-size: 2.44rem;
  text-transform: uppercase;
}
.c-top-h2 h2 > span {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  line-height: 1;
}
.c-top-h2 p {
  letter-spacing: 0.025em;
  display: block;
  font-size: 0.889rem;
  font-weight: bold;
  color: #827358;
  margin-left: 1em;
}
@media (max-width: 768px) {
  .c-top-h2 p {
    margin-left: 0;
  }
}

.c-post__thumb {
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff1d9;
}
.c-post__thumb img {
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .c-post:hover > .c-post__thumb img {
    transform: scale(1.05, 1.05);
  }
}
.c-post__detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.c-post__place {
  color: #827358;
  font-weight: bold;
  margin-bottom: 0;
}
.c-post__text {
  font-weight: bold;
}

.c-meta-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.c-meta-info svg {
  color: #827358;
  margin-right: 0.25em;
  width: 18px;
  height: 18px;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}

.c-category {
  background-color: #be924a;
  color: #fff8eb;
  padding: 0.25em 1em;
  border-radius: 50px;
  letter-spacing: 0.01em;
}

.c-time {
  color: #827358;
}

.c-button {
  position: relative;
  display: block;
  padding: 1em 0;
  border: 1px solid #3c2602;
  text-align: center;
  border-radius: 50px;
  text-decoration: none;
}

.c-shop {
  padding: 0.75em 2em;
  border: 1px solid rgba(130, 115, 88, 0.7);
  width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.c-shop svg {
  color: #fff8eb;
  margin-right: 0.5em;
  width: 20px;
  height: 20px;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}

.c-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-flow: column;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  font-size: 3.05rem;
  position: relative;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  font-feature-settings: unset;
}
@media (max-width: 768px) {
  .c-logo {
    font-size: 2.44rem;
  }
}
.c-logo span {
  font-size: 0.3em;
  display: block;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
}

.c-sidebar-header {
  background-color: #827358;
  color: #fff8eb;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  padding: 0.75em 1em;
  border-radius: 5px;
  margin-bottom: 1em;
}

.c-search {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(190, 146, 74, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  transition-property: border;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.c-search::after {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, 0px);
  background-color: #be924a;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .c-search:hover::after {
    top: 0px;
  }
}
@media (hover: hover) {
  .c-search:hover {
    border: 1px solid #be924a;
  }
  .c-search:hover svg {
    color: #fff8eb;
  }
}
@media (max-width: 768px) {
  .c-search {
    display: none;
  }
}
.c-search__content {
  z-index: 1;
}
.c-search__content svg {
  width: 18px;
  height: 18px;
  color: #be924a;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}

.c-menu-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(60, 38, 2, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  transition-property: border;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.c-menu-button::after {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, 0px);
  background-color: #3c2602;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .c-menu-button:hover::after {
    top: 0px;
  }
}
@media (hover: hover) {
  .c-menu-button:hover {
    border: 1px solid #3c2602;
  }
  .c-menu-button:hover svg {
    color: #fff8eb;
  }
}
@media (max-width: 768px) {
  .c-menu-button {
    width: 42px;
    height: 42px;
  }
}
.c-menu-button__content {
  z-index: 1;
}
.c-menu-button__content svg {
  width: 18px;
  height: 18px;
  color: #3c2602;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}

.c-close {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(255, 248, 235, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
}
.c-close::after {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, 0px);
  background-color: #fff8eb;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .c-close:hover::after {
    top: 0px;
  }
}
@media (hover: hover) {
  .c-close:hover svg {
    color: #3c2602;
  }
}
@media (max-width: 768px) {
  .c-close {
    width: 42px;
    height: 42px;
  }
}
.c-close__content {
  z-index: 1;
}
.c-close__content svg {
  width: 18px;
  height: 18px;
  color: #fff8eb;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}

.c-caution {
  border: 1px solid #cb3b28;
  padding: 1.5em;
  color: #cb3b28;
}
@media (max-width: 768px) {
  .c-caution {
    padding: 1.5em;
  }
}

.c-tag {
  margin: 0.3em;
}
.c-tag a {
  letter-spacing: 0.01em;
  font-size: 0.889rem;
  background-color: rgba(130, 115, 88, 0.1);
  border-radius: 4px;
  color: #3c2602;
  display: inline-block;
  padding: 0.25em 1em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.c-tag a:hover {
  background-color: #3c2602;
  color: #fff8eb;
}

.c-breadcrumbs a, .c-breadcrumbs span {
  font-size: 0.889rem;
}
.c-breadcrumbs span {
  color: #827358;
}
.c-breadcrumbs a {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .c-breadcrumbs a:hover {
    color: #827358;
  }
}

.c-loading {
  width: 100%;
  height: 100%;
  background-color: #3c2602;
  position: fixed;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  visibility: visible;
  opacity: 1;
}
.c-loading span {
  width: 0.75em;
  height: 0.75em;
  background-color: rgba(255, 248, 235, 0.7);
  border-radius: 50%;
  display: block;
  margin: 0 0.5em;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-name: bubble;
  animation-iteration-count: infinite;
}
.c-loading span:nth-of-type(2) {
  animation-delay: -0.33s;
}
.c-loading span:nth-of-type(3) {
  animation-delay: -0.66s;
}

@keyframes bubble {
  0% {
    opacity: 0;
    transform: translateY(300%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-300%);
  }
}
.c-searchbox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 850px;
  margin: 0 auto;
}
.c-searchbox input {
  font-size: 18px;
  padding: 0.5em 0.75em;
  width: 100%;
  height: 70px;
  margin-right: 0.5em;
  border-radius: 8px;
  border: none;
  background-color: #fff8eb;
  border: 1px solid #be924a;
}
@media (max-width: 768px) {
  .c-searchbox input {
    font-size: 16px;
  }
}
.c-searchbox button {
  background-color: #be924a;
  color: #fff1d9;
  width: 120px;
  height: 70px;
  border-radius: 8px;
}

.p-header {
  width: 100%;
  height: 100%;
  position: relative;
}
.p-header__shop {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  width: 220px;
  height: 50px;
  overflow: hidden;
  transition-property: border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.p-header__shop::after {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  left: 50%;
  width: 220px;
  height: 50px;
  transform: translate(-50%, 0px);
  background-color: #827358;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-header__shop:hover::after {
    top: 0px;
  }
}
@media (hover: hover) {
  .p-header__shop:hover {
    border-color: #827358;
  }
  .p-header__shop:hover span {
    color: #fff8eb;
  }
  .p-header__shop:hover svg {
    color: #fff8eb;
  }
}
@media (max-width: 1080px) {
  .p-header__shop {
    padding: 0;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .p-header__shop {
    width: 42px;
    height: 42px;
  }
}
.p-header__shop-content {
  z-index: 1;
  position: absolute;
  font-size: 1.125rem;
  color: #827358;
  text-transform: uppercase;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-header__shop-content svg {
  color: #827358;
}
@media (max-width: 1080px) {
  .p-header__shop-content svg {
    margin-right: 0;
  }
}
.p-header__shop-content__text {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (max-width: 1080px) {
  .p-header__shop-content__text {
    display: none;
  }
}
.p-header__logo {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-header__logo__text {
  transition-property: font-size;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.p-header__logo__text--downsize {
  font-size: 2.44rem;
}
@media (max-width: 768px) {
  .p-header__logo__text--downsize {
    font-size: 1.953rem;
  }
}
.p-header .c-search {
  position: absolute;
  top: 0;
  right: 64px;
  transform: translate(0, -50%);
}
.p-header .c-menu-button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.p-menu {
  background-color: #3c2602;
  width: 100%;
  height: 100%;
  z-index: 40;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  clip-path: circle(0% at 93% 11%);
  visibility: hidden;
  overflow-y: scroll;
}
.p-menu .l-header {
  background-color: transparent;
  z-index: 0;
}
.p-menu__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-flow: column;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  min-height: 100%;
  padding: 5em;
}
@media (max-width: 768px) {
  .p-menu__inner {
    padding: 2em;
  }
}
.p-menu__links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
}
@media (max-width: 768px) {
  .p-menu__links {
    flex-flow: column;
  }
}
.p-menu__title {
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  color: #827358;
  font-size: 1.563rem;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 0.5em;
}
.p-menu__list {
  margin-bottom: 2.5em;
}
@media (max-width: 768px) {
  .p-menu__list {
    margin-bottom: 1.5em;
  }
}
.p-menu__item {
  padding: 0.25em 0;
  margin-bottom: 0.25em;
}
.p-menu__item a {
  font-size: 1.125rem;
  letter-spacing: 0.01em;
  color: #fff8eb;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-menu__item a:hover {
    color: #827358;
  }
}
.p-menu__logo {
  color: #4f3914;
  font-size: 5rem;
}
@media (max-width: 768px) {
  .p-menu__logo {
    margin-top: 0.5em;
    font-size: 3rem;
    width: 100%;
  }
}
.p-menu .c-close {
  position: fixed;
  top: 10%;
  right: 5%;
  transform: translate(0, -50%) scale(0);
  z-index: 1;
}
.p-menu__search {
  margin-top: 2em;
}
.p-menu__search__box {
  width: 100%;
  margin-bottom: 3em;
}
.p-menu__search__box .c-searchbox {
  max-width: 100%;
}
.p-menu__search__title {
  font-size: 1.25rem;
  color: rgba(255, 248, 235, 0.7);
  font-weight: bold;
  margin-bottom: 0.5em;
  text-align: center;
}
.p-menu__search__tags__list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.p-menu__search__tags__item a {
  border: 1px solid rgba(255, 248, 235, 0.5);
  color: #fff8eb;
  background-color: transparent;
}
.p-menu__search__tags__item a:hover {
  background-color: #fff8eb;
  color: #3c2602;
}
.p-menu__search__tags__more {
  text-align: right;
}
.p-menu__search__tags__link {
  color: #fff8eb;
  position: relative;
  padding-right: 3em;
  display: inline-block;
}
@media (hover: hover) {
  .p-menu__search__tags__link:hover .p-menu__search__tags__link__arrow {
    right: -5px;
  }
}
.p-menu__search__tags__link__arrow {
  background-color: #3c2602;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  transition-property: right;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
  background-color: #827358;
}
.p-menu__search__tags__link__arrow::after {
  margin: auto;
  content: "";
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff8eb;
  border-right: 1px solid #fff8eb;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40%;
}

.p-mv .swiper-slide {
  width: 700px;
}
@media (max-width: 768px) {
  .p-mv .swiper-slide {
    width: 90%;
  }
}
.p-mv__post {
  display: block;
  position: relative;
}
.p-mv__post__thumb {
  position: relative;
  border-radius: 8px;
}
.p-mv__post__thumb::after {
  content: "";
  background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.6) 100%);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 768px) {
  .p-mv__post__thumb::after {
    background: unset;
  }
}
.p-mv__text-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1.5em;
  font-size: 1.25rem;
  width: 100%;
}
@media (max-width: 768px) {
  .p-mv__text-inner {
    padding: 0 0.5em 0.5em 0.5em;
    position: initial;
    bottom: unset;
    left: unset;
  }
}
.p-mv__detail, .p-mv__text, .p-mv__time {
  color: #fff8eb;
}
.p-mv__detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0.5em 0;
}
.p-mv__place {
  font-size: 1rem;
}
@media (max-width: 768px) {
  .p-mv__place {
    color: #827358;
  }
}
.p-mv__place p, .p-mv__place svg {
  color: #fff8eb;
}
@media (max-width: 768px) {
  .p-mv__place p, .p-mv__place svg {
    color: #827358;
  }
}
.p-mv__time {
  font-size: 0.889rem;
}
@media (max-width: 768px) {
  .p-mv__time {
    color: #827358;
  }
}
.p-mv__category {
  background-color: rgba(190, 146, 74, 0.9);
  font-size: 0.889rem;
}
.p-mv__text {
  margin-top: 0.25em;
  line-height: 1.4;
  font-size: 1.563rem;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 768px) {
  .p-mv__text {
    color: #3c2602;
  }
}
.p-mv__navigation {
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 768px) {
  .p-mv__navigation {
    margin-top: 1em;
  }
}
.p-mv__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-mv__pagination .swiper-pagination-bullet {
  margin: 0.25em;
  display: block;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  counter-increment: index;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
  background-color: transparent;
}
@media (hover: hover) {
  .p-mv__pagination .swiper-pagination-bullet:hover {
    background-color: rgba(60, 38, 2, 0.3);
  }
}
.p-mv__pagination .swiper-pagination-bullet:nth-child(n)::before {
  content: counter(index);
}
.p-mv__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1px solid #3c2602;
  background-color: #3c2602;
  color: #fff8eb;
}
.p-mv__button-prev, .p-mv__button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.5em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.p-mv__button-prev span, .p-mv__button-next span {
  width: 1.5em;
  height: 1.5em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}
@media (hover: hover) {
  .p-mv__button-prev:hover {
    transform: translateX(-5px);
  }
}
.p-mv__button-prev span {
  border-bottom: 1px solid #3c2602;
  border-left: 1px solid #3c2602;
}
@media (hover: hover) {
  .p-mv__button-next:hover {
    transform: translateX(5px);
  }
}
.p-mv__button-next span {
  border-top: 1px solid #3c2602;
  border-right: 1px solid #3c2602;
}

.p-info__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5em;
}
.p-info__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 768px) {
  .p-info__title {
    flex-flow: column;
    align-items: flex-start;
  }
}
.p-info__list-container {
  padding: 0 5% 0;
}
.p-info__item {
  width: 340px;
}
@media (max-width: 768px) {
  .p-info__item {
    width: 80%;
  }
}
.p-info__link {
  display: inline-block;
  position: relative;
  margin-top: 2.5em;
  padding-right: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (hover: hover) {
  .p-info__link:hover .p-info__link-arrow {
    right: -5px;
  }
}
@media (max-width: 768px) {
  .p-info__link {
    margin-top: 1em;
  }
}
.p-info__link-arrow {
  background-color: #3c2602;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  transition-property: right;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.p-info__link-arrow::after {
  margin: auto;
  content: "";
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff8eb;
  border-right: 1px solid #fff8eb;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40%;
}
.p-info__detail {
  margin: 0.75em 0 0.5em;
}
.p-info__category, .p-info__time {
  font-size: 0.889rem;
}
.p-info__text {
  margin-top: 0.15em;
}
.p-info__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-info__button-prev, .p-info__button-next {
  cursor: pointer;
  margin: 0.5em;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (max-width: 1080px) {
  .p-info__button-prev, .p-info__button-next {
    margin: 0.25em;
    padding: 0.25em;
  }
}
.p-info__button-prev span, .p-info__button-next span {
  width: 1.5em;
  height: 1.5em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
}
.p-info__button-prev.swiper-button-disabled, .p-info__button-next.swiper-button-disabled {
  opacity: 0.2;
}
@media (hover: hover) {
  .p-info__button-prev.swiper-button-disabled:hover, .p-info__button-next.swiper-button-disabled:hover {
    opacity: 0.2;
    transform: translateX(0px);
  }
}
@media (hover: hover) {
  .p-info__button-prev:hover {
    transform: translateX(-5px);
  }
}
.p-info__button-prev span {
  border-bottom: 1px solid #3c2602;
  border-left: 1px solid #3c2602;
}
@media (hover: hover) {
  .p-info__button-next:hover {
    transform: translateX(5px);
  }
}
.p-info__button-next span {
  border-top: 1px solid #3c2602;
  border-right: 1px solid #3c2602;
}
.p-info__place {
  font-size: 0.889rem;
  font-weight: bold;
}

.p-blog__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5em;
}
@media (max-width: 768px) {
  .p-blog__title {
    flex-flow: column;
    align-items: flex-start;
  }
}
.p-blog__toggle {
  display: none;
  margin-bottom: 2em;
}
@media (max-width: 1080px) {
  .p-blog__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.p-blog__toggle__tab {
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  color: #827358;
  border: 1px solid #827358;
  border-radius: 5px;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 56px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.p-blog__toggle__tab--active {
  background-color: #827358;
  color: #fff8eb;
}
.p-blog__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 1080px) {
  .p-blog__list {
    flex-flow: column;
    justify-content: flex-start;
  }
}
.p-blog__item {
  width: 48%;
  margin-bottom: 2em;
}
@media (max-width: 1080px) {
  .p-blog__item {
    width: 100%;
  }
}
@media (max-width: 1080px) {
  .p-blog__item:nth-child(n+6) {
    display: none;
  }
}
.p-blog__button {
  margin: 2em auto;
  width: 100%;
  max-width: 350px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.p-blog__button::after {
  content: "";
  display: block;
  position: absolute;
  top: 65px;
  left: 50%;
  width: 350px;
  height: 65px;
  transform: translate(-50%, 0px);
  background-color: #3c2602;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-blog__button:hover::after {
    top: 0px;
  }
}
@media (hover: hover) {
  .p-blog__button:hover {
    color: #fff8eb;
  }
}
.p-blog__button-content {
  z-index: 1;
}
.p-blog__detail {
  margin: 0.75em 0 0.5em;
}
.p-blog__text {
  font-weight: bold;
  font-size: 1.125rem;
  margin-top: 0.1em;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.p-blog__place {
  font-size: 0.889rem;
  font-weight: bold;
}
.p-blog__category, .p-blog__time {
  font-size: 0.889rem;
}

.p-toggle-list {
  display: none;
}
.p-toggle-list--active {
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 1080px) {
  .p-toggle-list--active {
    flex-flow: column;
    justify-content: flex-start;
  }
}

.p-sidebar__button {
  width: 100%;
  height: 58px;
  margin: 2em auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-sidebar__button::after {
  content: "";
  display: block;
  position: absolute;
  top: 58px;
  left: 50%;
  width: 100%;
  height: 58px;
  transform: translate(-50%, 0px);
  background-color: #3c2602;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-sidebar__button:hover::after {
    top: 0px;
  }
}
@media (hover: hover) {
  .p-sidebar__button:hover .p-sidebar__button-content {
    color: #fff8eb;
  }
}
.p-sidebar__button-content {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
  z-index: 1;
}
.p-sidebar__youtube {
  width: 100%;
  height: 200px;
  margin-bottom: 1em;
}
.p-sidebar__youtube iframe {
  width: 100%;
  height: 100%;
}

.p-ranking {
  margin-bottom: 3em;
}
@media (max-width: 768px) {
  .p-ranking {
    display: none;
  }
}
.p-ranking__item {
  width: 100%;
  margin-bottom: 2em;
}
.p-ranking__item:nth-child(1) .p-ranking__thumb::before {
  content: "01";
  background-color: rgba(237, 192, 12, 0.9);
}
.p-ranking__item:nth-child(2) .p-ranking__thumb::before {
  content: "02";
  background-color: rgba(103, 103, 103, 0.9);
}
.p-ranking__item:nth-child(3) .p-ranking__thumb::before {
  content: "03";
  background-color: rgba(190, 146, 74, 0.9);
}
.p-ranking__item:nth-child(4) .p-ranking__thumb::before {
  content: "04";
  background-color: rgba(130, 115, 88, 0.9);
}
.p-ranking__item:nth-child(5) .p-ranking__thumb::before {
  content: "05";
  background-color: rgba(60, 38, 2, 0.9);
}
@media (max-width: 768px) {
  .p-ranking__item {
    margin-bottom: 2em;
  }
}
.p-ranking__detail {
  margin: 0.5em 0;
}
.p-ranking__text {
  font-weight: bold;
  font-size: 0.889rem;
  margin-top: 0.1em;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 768px) {
  .p-ranking__text {
    font-size: 1.125rem;
  }
}
.p-ranking__place {
  font-size: 0.8rem;
}
@media (max-width: 768px) {
  .p-ranking__place {
    font-size: 0.889rem;
  }
}
.p-ranking__category, .p-ranking__time {
  font-size: 0.8rem;
}
@media (max-width: 768px) {
  .p-ranking__category, .p-ranking__time {
    font-size: 0.889rem;
  }
}
.p-ranking__thumb {
  position: relative;
}
.p-ranking__thumb::before {
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 1.25rem;
  color: #fff8eb;
  display: block;
  padding: 0.5em 0.75em;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-ranking .c-sidebar-header {
  text-transform: uppercase;
}

.p-categories {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 768px) {
  .p-categories {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}
.p-categories__h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff8eb;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  line-height: 1.2;
  z-index: 2;
  text-transform: uppercase;
}
.p-categories__h2 span {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
}
.p-categories__item {
  width: 33.3333%;
  padding-bottom: 25%;
  display: block;
  position: relative;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-right: 1px solid #fff8eb;
  border-bottom: 1px solid #fff8eb;
}
.p-categories__item:last-child {
  border-right: none;
}
@media (max-width: 768px) {
  .p-categories__item {
    border-right: none;
    width: 100%;
    height: 280px;
    padding-bottom: 40%;
  }
}
.p-categories__item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-categories__item:hover::after {
    opacity: 0;
  }
}
.p-categories__item1 {
  background-image: url(/wp-content/themes/banshaku/images/interview.jpg);
}
.p-categories__item1::after {
  background-image: url(/wp-content/themes/banshaku/images/interview-bw.jpg);
}
.p-categories__item2 {
  background-image: url(/wp-content/themes/banshaku/images/column.jpg);
}
.p-categories__item2::after {
  background-image: url(/wp-content/themes/banshaku/images/column-bw.jpg);
}
.p-categories__item3 {
  background-image: url(/wp-content/themes/banshaku/images/review.jpg);
}
.p-categories__item3::after {
  background-image: url(/wp-content/themes/banshaku/images/review-bw.jpg);
}

.p-contact {
  background-color: rgba(66, 97, 126, 0.6);
  padding: 3em;
  max-width: 590px;
}
@media (max-width: 768px) {
  .p-contact {
    padding: 5%;
  }
}
.p-contact__text {
  color: #fff8eb;
}
.p-contact__text p {
  margin-bottom: 1.2em;
}
.p-contact__button {
  margin-bottom: 0;
  margin-top: 2em;
  border: 1px solid white;
  font-size: 1rem;
  padding: 1em;
  width: 100%;
  height: 60px;
  color: white;
  overflow: hidden;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-contact__button::after {
  content: "";
  display: block;
  position: absolute;
  top: 60px;
  left: 50%;
  width: 100%;
  height: 60px;
  transform: translate(-50%, 0px);
  background-color: white;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-contact__button:hover::after {
    top: 0px;
  }
}
@media (hover: hover) {
  .p-contact__button:hover {
    color: #42617e;
  }
}
.p-contact__button-content {
  z-index: 1;
}

.p-footer__nav {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
}
.p-footer__menu {
  margin-bottom: 5em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 768px) {
  .p-footer__menu {
    display: none;
  }
}
.p-footer__menu__group p {
  color: #827358;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  font-size: 1.563rem;
  font-weight: normal;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}
.p-footer__menu__list li {
  margin-bottom: 1em;
}
.p-footer__menu__list a {
  color: #fff8eb;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.p-footer__menu__list a::before {
  content: "";
}
@media (hover: hover) {
  .p-footer__menu__list a:hover {
    color: #827358;
  }
}
.p-footer__sns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-footer__sns__link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #827358;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  margin-right: 1.5em;
  overflow: hidden;
}
.p-footer__sns__link::after {
  content: "";
  display: block;
  position: absolute;
  top: 54px;
  left: 50%;
  width: 54px;
  height: 54px;
  transform: translate(-50%, 0px);
  background-color: #827358;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-footer__sns__link:hover::after {
    top: 0px;
  }
}
.p-footer__sns__link:last-child {
  margin-right: 0;
}
.p-footer__sns-content {
  z-index: 1;
}
.p-footer__sns-tw svg {
  width: 20px;
  height: 20px;
  color: #fff8eb;
}
.p-footer__sns-fb svg {
  width: 19px;
  height: 19px;
  color: #fff8eb;
}
.p-footer__sns-ig svg {
  width: 19px;
  height: 19px;
  color: #fff8eb;
}
.p-footer__sns-yt svg {
  width: 22px;
  height: 22px;
  color: #fff8eb;
}
.p-footer__shop {
  margin: 2.5em auto 0;
  padding: 0.75em 2em;
  border-color: #827358;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: #fff8eb;
  overflow: hidden;
  text-transform: uppercase;
}
.p-footer__shop::after {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  left: 50%;
  width: 220px;
  height: 50px;
  transform: translate(-50%, 0px);
  background-color: #827358;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-footer__shop:hover::after {
    top: 0px;
  }
}
.p-footer__shop-content {
  z-index: 1;
  font-size: 1.125rem;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-footer__logo {
  margin-top: 6em;
  border-top: 1px solid #827358;
  padding: 4rem 0 2rem;
}
.p-footer__logo a {
  display: block;
  opacity: 1;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-footer__logo a:hover {
    color: #827358;
  }
}
.p-footer__logo p {
  color: #fff8eb;
}
.p-footer__copy {
  color: #827358;
  text-align: center;
}

.p-recommend {
  background-color: #fff1d9;
}
.p-recommend__title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.p-recommend__title h3 {
  font-size: 2.44rem;
  text-transform: uppercase;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
}
.p-recommend__title p {
  letter-spacing: 0.025em;
  display: block;
  font-size: 0.889rem;
  font-weight: bold;
  color: #827358;
  margin-left: 1em;
}
@media (max-width: 768px) {
  .p-recommend__title p {
    margin-left: 0;
  }
}
.p-recommend__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5em;
}
@media (max-width: 768px) {
  .p-recommend__title {
    flex-flow: column;
    align-items: flex-start;
  }
}
.p-recommend__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 1080px) {
  .p-recommend__list {
    flex-flow: column;
    justify-content: flex-start;
  }
}
.p-recommend__item {
  width: 32%;
  margin-bottom: 2em;
}
@media (max-width: 1080px) {
  .p-recommend__item {
    width: 100%;
  }
}
.p-recommend__detail {
  margin: 0.75em 0 0.5em;
}
.p-recommend__text {
  font-weight: bold;
  font-size: 1.125rem;
  margin-top: 0.1em;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.p-recommend__place {
  font-size: 0.889rem;
  font-weight: bold;
}
.p-recommend__category, .p-recommend__time {
  font-size: 0.889rem;
}

.p-page__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4em;
}
@media (max-width: 768px) {
  .p-page__title {
    flex-flow: column;
    align-items: flex-start;
  }
}
.p-page__title__large {
  font-size: 3.05rem;
  text-transform: uppercase;
  line-height: 1;
}
.p-page__title__small {
  letter-spacing: 0.025em;
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  color: #827358;
  margin-left: 1em;
}
@media (max-width: 768px) {
  .p-page__title__small {
    margin-left: 0;
  }
}
.p-page__thumb {
  margin-bottom: 1.5em;
}
.p-page__place {
  margin-bottom: 0.25em;
}
.p-page__single-title {
  margin-bottom: 0.75em;
  font-size: 1.953rem;
  line-height: 1.3;
}
.p-page__main {
  margin-bottom: 5em;
}
@media (max-width: 768px) {
  .p-page__main {
    margin-bottom: 2.5em;
  }
}
.p-page__main h2 {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 1.563rem;
  font-weight: bold;
  border-bottom: 1px solid #827358;
  padding: 0.5em 0;
  margin-bottom: 1em;
}
.p-page__main h3 {
  font-size: 1.25rem;
  background-color: #827358;
  color: #fff8eb;
  border-radius: 4px;
  padding: 0.5em 1em;
  margin-bottom: 1em;
}
.p-page__main h4 {
  font-size: 1.125rem;
  margin-bottom: 1em;
}
.p-page__main img {
  border-radius: 4px !important;
  margin-bottom: 1.5em;
  width: 100%;
}
.p-page__main p {
  margin-bottom: 1.5em;
  text-align: justify;
  word-break: break-all;
  white-space: pre-wrap;
}
.p-page__main p a {
  position: relative;
  padding-left: 1em;
  font-weight: bold;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-page__main p a:hover {
    opacity: 0.7;
  }
}
.p-page__main p a::before {
  content: "";
  margin: auto;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  border-top: 1px solid #3c2602;
  border-right: 1px solid #3c2602;
  transform: rotate(45deg);
  position: absolute;
  top: 4px;
  left: 0;
}
.p-page__main ul {
  list-style: disc;
  padding-left: 1em;
  margin-bottom: 1.5em;
}
.p-page__main ul li {
  margin-bottom: 0.5em;
}
.p-page__main li a {
  font-weight: bold;
}
.p-page__main table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 1.5em;
}
.p-page__main table tr {
  width: 100%;
  margin-bottom: 1em;
  border-bottom: 1px solid rgba(57, 39, 9, 0.3);
}
.p-page__main table tr:first-child {
  border-top: 1px solid rgba(57, 39, 9, 0.3);
}
.p-page__main table th {
  width: 30%;
  text-align: left;
  padding: 1em;
}
@media (max-width: 768px) {
  .p-page__main table th {
    padding-bottom: 0;
    width: 100%;
    display: block;
  }
}
.p-page__main table td {
  padding: 1em;
  width: 70%;
}
@media (max-width: 768px) {
  .p-page__main table td {
    width: 100%;
    display: block;
  }
}
.p-page__main section {
  padding: 1.5em 0 3em;
}
.p-page .page-index {
  list-style: decimal;
  margin: 2.5em 0;
  padding: 2em 1.5em 1.5em 3em;
  border: 1px solid #827358;
  position: relative;
}
.p-page .page-index::before {
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  content: "CHAPTER";
  display: block;
  padding: 0 0.5em;
  background-color: #fff8eb;
  position: absolute;
  font-size: 1.25rem;
  color: #827358;
  top: -16px;
  left: 1em;
}
.p-page .page-index li {
  margin-bottom: 0.5em;
}
.p-page .page-index li:last-child {
  margin-bottom: 0;
}
.p-page .page-index li > a {
  font-weight: bold;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-page .page-index li > a:hover {
    color: #827358;
  }
}
.p-page .directors-voice {
  background-color: rgba(255, 210, 21, 0.15);
  padding: 2em;
  margin-bottom: 1.5em;
}
.p-page .directors-voice h3 {
  color: #be924a;
  background-color: unset;
  text-align: center;
  font-size: 1.563rem;
  padding: 0;
  margin-bottom: 0.5em;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
}
.p-page .directors-voice-editor {
  margin-bottom: 0;
  text-align: right;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  color: #827358;
}
.p-page .directors-voice-editor::before {
  content: "Text：";
}
.p-page .mission-h2 {
  text-align: center;
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  border-bottom: none;
  margin-bottom: 0;
  font-size: 1.25rem;
  color: #be924a;
}
.p-page .mission-h3 {
  background-color: transparent;
  color: #3c2602;
  font-weight: bold;
  font-size: 1.563rem;
  text-align: center;
  padding: 0;
}
.p-page__tags {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1em;
}
.p-page__detail {
  margin-bottom: 1em;
}
.p-page__text {
  font-weight: bold;
  font-size: 1.563rem;
  margin-top: 0.1em;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.p-page__place {
  font-weight: bold;
}
.p-page__category {
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.p-page__category:hover {
  background-color: #3c2602;
}
.p-page__sns {
  margin-top: 5em;
}
.p-page__sns p {
  text-align: center;
  color: #827358;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 0.75em;
}
.p-page__sns__links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.p-page__sns__link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid rgba(60, 38, 2, 0.7);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  margin-right: 1.5em;
  overflow: hidden;
}
.p-page__sns__link::after {
  content: "";
  display: block;
  position: absolute;
  top: 54px;
  left: 50%;
  width: 54px;
  height: 54px;
  transform: translate(-50%, 0px);
  background-color: #3c2602;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-page__sns__link:hover::after {
    top: 0px;
  }
}
.p-page__sns__link:last-child {
  margin-right: 0;
}
.p-page__sns__link svg {
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-page__sns__link:hover svg {
    color: #fff8eb;
  }
}
.p-page__sns-content {
  z-index: 1;
}
.p-page__sns-tw svg {
  width: 20px;
  height: 20px;
  color: #3c2602;
}
.p-page__sns-fb svg {
  width: 19px;
  height: 19px;
  color: #3c2602;
}

.p-sitemap {
  margin-top: 5em;
}
.p-sitemap__title {
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
  color: #fff8eb;
  padding: 0.25em 0.5em;
  margin-bottom: 0.5em;
  border-radius: 5px;
  font-size: 1.563rem;
  text-transform: uppercase;
  background-color: #827358;
}
.p-sitemap__list {
  margin: 0 0.75em;
  margin-bottom: 2.5em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .p-sitemap__list {
    flex-flow: column;
  }
}
.p-sitemap__item {
  padding: 0.25em 0;
  margin-right: 1em;
}
.p-sitemap__item::after {
  content: "/";
  color: #827358;
  margin-left: 1em;
  font-size: 1.125rem;
}
@media (max-width: 768px) {
  .p-sitemap__item::after {
    display: none;
  }
}
.p-sitemap__item:last-child::after {
  display: none;
}
.p-sitemap__item a {
  font-size: 1.25rem;
  letter-spacing: 0.01em;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-sitemap__item a:hover {
    color: #827358;
  }
}

.p-archive__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4em;
}
@media (max-width: 768px) {
  .p-archive__title {
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 3em;
  }
}
.p-archive__title__large {
  font-size: 3.05rem;
  text-transform: uppercase;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .p-archive__title__large {
    font-size: 2.44rem;
  }
}
.p-archive__title__small {
  letter-spacing: 0.025em;
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  color: #827358;
  margin-left: 1em;
}
@media (max-width: 768px) {
  .p-archive__title__small {
    margin-left: 0;
    font-size: 1.125rem;
  }
}
.p-archive__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 1080px) {
  .p-archive__list {
    flex-flow: column;
    justify-content: flex-start;
  }
}
.p-archive__item {
  width: 48%;
  margin-bottom: 2.5em;
}
@media (max-width: 1080px) {
  .p-archive__item {
    width: 100%;
  }
}
.p-archive__detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0.75em 0 0.5em;
}
.p-archive__text {
  font-weight: bold;
  font-size: 1.25rem;
  margin-top: 0.1em;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 768px) {
  .p-archive__text {
    font-size: 1.25rem;
  }
}
.p-archive__place {
  font-weight: bold;
}
@media (max-width: 768px) {
  .p-archive__category, .p-archive__time {
    font-size: 0.889rem;
  }
}
.p-archive__cats {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5em;
}
@media (max-width: 768px) {
  .p-archive__cats {
    margin-bottom: 3em;
    flex-flow: column;
  }
}
.p-archive__cats__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #827358;
  border-radius: 5px;
  font-size: 1.125rem;
  font-weight: normal;
  color: #827358;
  width: 200px;
  height: 56px;
  line-height: 1.2;
  text-align: center;
  margin-right: 1em;
  margin-bottom: 1em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
  position: relative;
  overflow: hidden;
}
.p-archive__cats__item::after {
  content: "";
  display: block;
  position: absolute;
  top: 56px;
  left: 50%;
  width: 200px;
  height: 56px;
  transform: translate(-50%, 0px);
  background-color: #827358;
  z-index: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .p-archive__cats__item:hover::after {
    top: 0px;
  }
}
@media (max-width: 768px) {
  .p-archive__cats__item {
    width: 100%;
    height: 40px;
    margin-bottom: 0.5em;
    margin-right: 0;
    overflow: hidden;
  }
  .p-archive__cats__item::after {
    content: "";
    display: block;
    position: absolute;
    top: 56px;
    left: 50%;
    width: 100%;
    height: 56px;
    transform: translate(-50%, 0px);
    background-color: #827358;
    z-index: 0;
    transition-property: top;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    transition-delay: 0.1s;
  }
}
@media (max-width: 768px) and (hover: hover) {
  .p-archive__cats__item:hover::after {
    top: 0px;
  }
}
@media (hover: hover) {
  .p-archive__cats__item:hover {
    color: #fff8eb;
  }
}
.p-archive__cats__item__content {
  z-index: 1;
}
.p-archive__cats--active {
  background-color: #827358;
  color: #fff8eb;
}

.p-search__title {
  font-size: 1.953rem;
}

.fixed {
  overflow: hidden;
}

.position-right {
  text-align: right;
}

.fit-img {
  display: block;
  position: relative;
  padding-bottom: 65%;
}
.fit-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
  position: absolute;
  left: 0;
  top: 0;
}

.lazyload {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.lazyload.loaded {
  opacity: 1;
}

.large {
  font-size: 1.125rem;
}

.center {
  text-align: center;
}

.mb-m {
  margin-bottom: 1.5em;
}

.belleza {
  font-family: "Belleza", sans-serif;
  letter-spacing: 0.05em;
  font-weight: normal;
}

.narrow {
  max-width: 960px;
  margin: 0 auto;
}

.wp-pagenavi {
  margin: 2.5em 5%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 768px) {
  .wp-pagenavi {
    margin: 0 5%;
  }
}
.wp-pagenavi span.pages,
.wp-pagenavi span.extend,
.wp-pagenavi .nextpostslink,
.wp-pagenavi .previouspostslink {
  display: none;
}
.wp-pagenavi span.current,
.wp-pagenavi .smaller,
.wp-pagenavi .larger,
.wp-pagenavi .first,
.wp-pagenavi .last {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border: none;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  font-size: 1.125rem;
  margin: 0.25em;
}
.wp-pagenavi span.current {
  background-color: #3c2602;
  color: #fff8eb;
  font-weight: normal;
}
.wp-pagenavi .smaller,
.wp-pagenavi .larger,
.wp-pagenavi .first,
.wp-pagenavi .last {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
.wp-pagenavi .smaller:hover,
.wp-pagenavi .larger:hover,
.wp-pagenavi .first:hover,
.wp-pagenavi .last:hover {
  background-color: rgba(60, 38, 2, 0.1);
}

.wpcf7 .acceptance {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-flow: column;
  background-color: #fff1d9;
  padding: 2em;
}
.wpcf7 p {
  margin-bottom: 0.5em;
}
.wpcf7 .submit {
  margin: 2em 0;
}
.wpcf7 .submit input[type=submit] {
  max-width: 280px;
  width: 100%;
  margin: 0 auto 1em;
  padding: 1em;
  text-align: center;
  display: block;
  color: #fff8eb;
  background-color: #3c2602;
  border: 1px solid #3c2602;
  border-radius: 50px;
  appearance: none;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0.1s;
}
@media (hover: hover) {
  .wpcf7 .submit input[type=submit]:hover {
    opacity: 0.7;
  }
}
.wpcf7 .submit input[type=submit]:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.wp-block-image {
  margin-bottom: 1.5em;
}
.wp-block-image img {
  margin: 0 auto 0.5em;
}
.wp-block-image figcaption {
  display: block !important;
}

.liquid-speech-balloon-wrap {
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.liquid-speech-balloon-bubble {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: #fff1d9;
  padding: 2em 3em 4em;
}
.liquid-speech-balloon-bubble::before, .liquid-speech-balloon-bubble::after {
  display: none !important;
}
@media (max-width: 768px) {
  .liquid-speech-balloon-bubble {
    padding: 1.5em;
  }
}
.liquid-speech-balloon-bubble .liquid-speech-balloon-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .liquid-speech-balloon-bubble .liquid-speech-balloon-avatar {
    width: 64px;
    height: 64px;
  }
}
.liquid-speech-balloon-bubble .liquid-speech-balloon-text {
  position: relative;
  z-index: 1;
  border-radius: 0px;
  padding: 0;
  width: 100%;
  border: none;
}

.liquid-speech-balloon-text {
  background-color: unset;
  border: 1px solid #827358;
  padding: 1.5em;
}
.liquid-speech-balloon-text p {
  margin-bottom: 0 !important;
}

.liquid-speech-balloon-avatar {
  background-size: cover;
  background-position: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .liquid-speech-balloon-avatar {
    width: 64px;
    height: 64px;
  }
}
.liquid-speech-balloon-avatar::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  left: 0;
  top: 85px;
  font-size: 0.75rem;
  line-height: 1.25;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.01em;
}
@media (max-width: 768px) {
  .liquid-speech-balloon-avatar::after {
    top: 70px;
  }
}

.liquid-speech-balloon-right .liquid-speech-balloon-text {
  margin: 0 2em 0 0;
}

.liquid-speech-balloon-text {
  margin: 0 0 0 2em;
}

.liquid-speech-balloon-text strong {
  margin-bottom: 0.5em;
}
.liquid-speech-balloon-text strong span {
  color: #42617E !important;
}

.liquid-speech-balloon-00 .liquid-speech-balloon-text strong {
  margin-bottom: 0.5em;
}
.liquid-speech-balloon-00 .liquid-speech-balloon-text strong span {
  color: #be924a !important;
}

.liquid-speech-balloon-wrap:not(.liquid-speech-balloon-vertical) .liquid-speech-balloon-arrow {
  border-right: 10px solid #827358;
}

.liquid-speech-balloon-wrap.liquid-speech-balloon-right .liquid-speech-balloon-arrow {
  border-left: 10px solid #827358;
  border-right: 7px solid transparent;
}

.wpcf7-not-valid-tip {
  margin-top: 0.5em;
}

.wp-block-embed-youtube iframe {
  height: 460px;
}
@media (max-width: 768px) {
  .wp-block-embed-youtube iframe {
    height: 200px;
  }
}

.wp-block-embed iframe {
  width: 100%;
}

.wp-embed {
  background-color: transparent;
}

.p-sp-search {
  display: none;
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
  z-index: 30;
}
@media (max-width: 768px) {
  .p-sp-search {
    display: flex;
    width: 42px;
    height: 42px;
  }
}

.gmap {
  width: 100%;
}

.instagram-media,
.twitter-tweet {
  margin: 0px auto 1em !important;
}