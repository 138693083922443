
@use '../../foundation/variables' as v;

.p-sidebar {
  &__button {
    width: 100%;
    height: 58px;
    margin: 2em auto;
    @include v.button-hover(100%, 58px, v.$primary-dark);
    @include v.flexbox(center, center, nowrap);
    @media(hover: hover) {
      &:hover {
        .p-sidebar__button-content {
          color: v.$beige-light;
        }
      }
    }
  }
  &__button-content {
    @include v.animation(color);
    z-index: 1;
  }
  &__youtube {
    width: 100%;
    height: 200px;
    margin-bottom: 1em;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}