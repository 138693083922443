@use '../../foundation/variables' as v;

.p-header {
  width: 100%;
  height: 100%;
  position: relative;
  &__shop {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 220px;
    height: 50px;
    @include v.button-hover(220px, 50px, v.$primary);
    @include v.animation(border-color);
    @media (hover: hover) {
      &:hover {
        border-color: v.$primary;
        span {
          color: v.$beige-light;
        }
        svg {
          color: v.$beige-light;
        }
      }
    }
    @include v.tb {
      padding: 0;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include v.sp {
      width: 42px;
      height: 42px;
    }
  }
  &__shop-content {
    z-index: 1;
    position: absolute;
    font-size: v.$scale-up6;
    color: v.$primary;
    text-transform: uppercase;
    @include v.belleza();
    @include v.flexbox(center, center, nowrap);
    svg {
      color: v.$primary;
      @include v.tb {
        margin-right: 0;
      }
    }
    &__text {
      @include v.animation(color);
      @include v.tb {
        display: none;
      }
    }
  }
  &__logo {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &__text {
      @include v.animation(font-size);
      &--downsize {
        font-size: v.$scale-up2;
        @include v.sp {
          font-size: v.$scale-up3;
        }
      }
    }
  }
  .c-search {
    position: absolute;
    top: 0;
    right: 64px;
    transform: translate(0, -50%);
  }
  .c-menu-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
}

.p-menu {
  background-color: v.$primary-dark;
  width: 100%;
  height: 100%;
  z-index: map-get(v.$z, menu);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  clip-path: circle(0% at 93% 11%);
  visibility: hidden;
  overflow-y: scroll;
  .l-header {
    background-color: transparent;
    z-index: 0;
  }
  &__inner {
    @include v.flexbox(center, center, nowrap);
    flex-flow: column;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    min-height: 100%;
    padding: 5em;
    @include v.sp {
      padding: 2em;
    }
  }
  &__links {
    @include v.flexbox(between, start, nowrap);
    width: 100%;
    @include v.sp {
      flex-flow: column;
    }
  }
  &__title {
    @include v.belleza;
    color: v.$primary;
    font-size: v.$scale-up4;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0.5em;
  }
  &__list {
    margin-bottom: 2.5em;
    @include v.sp {
      margin-bottom: 1.5em;
    }
  }
  &__item {
    padding: 0.25em 0;
    margin-bottom: 0.25em;
    a {
      font-size: v.$scale-up6;
      letter-spacing: 0.01em;
      color: v.$beige-light;
      @include v.animation(color);
      @media(hover: hover) {
        &:hover {
          color: v.$primary;
        }
      }
    }
  }
  &__logo {
    color: #4f3914;
    font-size: 5rem;
    @include v.sp {
      margin-top: 0.5em;
      font-size: 3rem;
      width: 100%;
    }
  }
  .c-close {
    position: fixed;
    top: 10%;
    right: 5%;
    transform: translate(0, -50%) scale(0);
    z-index: 1;
  }
  &__search {
    margin-top: 2em;
    &__box {
      width: 100%;
      margin-bottom: 3em;
      .c-searchbox {
        max-width: 100%;
      }
    }
    &__title {
      font-size: v.$scale-up5;
      color:rgba(255, 248, 235, 0.7);
      font-weight: bold;
      margin-bottom: 0.5em;
      text-align: center;
    }
    &__tags {
      &__list {
        @include v.flexbox(start, start, wrap);
        margin-bottom: 1em;
      }
      &__item {
        a {
          border: 1px solid rgba($color: v.$beige-light, $alpha: 0.5);
          color: v.$beige-light;
          background-color: transparent;
          &:hover {
            background-color: v.$beige-light;
            color: v.$primary-dark;
          }
        }
      }
      &__more {
        text-align: right;
      }
      &__link {
        color: v.$beige-light;
        position: relative;
        padding-right: 3em;
        display: inline-block;
        @media (hover: hover) {
          &:hover {
            .p-menu__search__tags__link__arrow {
              right: -5px;
            }
          }
        }
        &__arrow {
          @include v.arrow();
          @include v.animation(right);
          background-color: v.$primary;
        }
      }
    }
  }
}