@use '../../foundation/variables' as v;

.p-categories {
  @include v.flexbox();
  @include v.sp {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  &__h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: v.$beige-light;
    @include v.belleza;
    line-height: 1.2;
    z-index: 2;
    text-transform: uppercase;
    span {
      display: block;
      font-size: 1rem;
      font-weight: normal;
      @include v.default-font;
    }
  }
  &__item {
    width: 33.3333%;
    padding-bottom: 25%;
    display: block;
    position: relative;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-right: 1px solid v.$beige-light;
    border-bottom: 1px solid v.$beige-light;
    &:last-child {
      border-right: none;
    }
    @include v.sp {
      border-right: none;
      width: 100%;
      height: 280px;
      padding-bottom: 40%;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      opacity: 1;
      @include v.animation(opacity);
    }
    @media (hover: hover) {
      &:hover {
        &::after {
          opacity: 0;
        }
      }
    }
  }
  &__item1 {
    background-image: url(/wp-content/themes/banshaku/images/interview.jpg);
    &::after {
      background-image: url(/wp-content/themes/banshaku/images/interview-bw.jpg);
    }
  }
  &__item2 {
    background-image: url(/wp-content/themes/banshaku/images/column.jpg);
    &::after {
      background-image: url(/wp-content/themes/banshaku/images/column-bw.jpg);
    }
  }
  &__item3 {
    background-image: url(/wp-content/themes/banshaku/images/review.jpg);
    &::after {
      background-image: url(/wp-content/themes/banshaku/images/review-bw.jpg);
    }
  }
}

.p-contact {
  background-color: rgba(66, 97, 126, 0.6);
  padding: 3em;
  max-width: 590px;
  @include v.sp {
    padding: 5%;
  }
  &__text {
    color: v.$beige-light;
    p {
      margin-bottom: 1.2em;
    }
  }
  &__button {
    margin-bottom: 0;
    margin-top: 2em;
    border: 1px solid white;
    font-size: 1rem;
    padding: 1em;
    width: 100%;
    height: 60px;
    color: white;
    @include v.button-hover(100%, 60px, white);
    @include v.animation(color);
    @include v.flexbox(center, center, nowrap);
    @media(hover: hover) {
      &:hover {
        color: v.$blue;
      }
    }
  }
  &__button-content {
    z-index: 1;
  }
}

.p-footer {
  &__nav {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
  }
  &__menu {
    margin-bottom: 5em;
    @include v.flexbox(between, start, nowrap);
    @include v.sp {
      display: none;
    }
    &__group {
      p {
        color: v.$primary;
        @include v.belleza;
        font-size: v.$scale-up4;
        font-weight: normal;
        margin-bottom: 0.5em;
        text-transform: uppercase;
      }
    }
    &__list {
      li {
        margin-bottom: 1em;
      }
      a {
        color: v.$beige-light;
        &::before {
          content: '';
        }
        @include v.animation(color);
        @media (hover: hover) {
          &:hover {
            color: v.$primary;
          }
        }
      }
    }
  }
  &__sns {
    @include v.flexbox(center, center, nowrap);
    &__link {
      @include v.flexbox(center, center, nowrap);
      border: 1px solid v.$primary;
      border-radius: 50%;
      width: 54px;
      height: 54px;
      margin-right: 1.5em;
      @include v.button-hover(54px, 54px, v.$primary);
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__sns-content {
    z-index: 1;
  }
  &__sns-tw {
    svg {
      width: 20px;
      height: 20px;
      color: v.$beige-light;
    }
  }
  &__sns-fb {
    svg {
      width: 19px;
      height: 19px;
      color: v.$beige-light;
    }
  }
  &__sns-ig {
    svg {
      width: 19px;
      height: 19px;
      color: v.$beige-light;
    }
  }
  &__sns-yt {
    svg {
      width: 22px;
      height: 22px;
      color: v.$beige-light;
    }
  }
  &__shop {
    margin: 2.5em auto 0;
    padding: .75em 2em;
    border-color: v.$primary;
    @include v.flexbox(center, center, nowrap);
    color: v.$beige-light;
    @include v.button-hover(220px, 50px, v.$primary);
    text-transform: uppercase;
  }
  &__shop-content {
    z-index: 1;
    font-size: v.$scale-up6;
    @include v.belleza();
    @include v.flexbox(center, center, nowrap);
  }
  &__logo {
    margin-top: 6em;
    border-top: 1px solid v.$primary;
    padding: 4rem 0 2rem;
    a {
      display: block;
      opacity: 1;
      @include v.animation;
      @media (hover: hover) {
        &:hover {
          color: v.$primary;
        }
      }
    }
    p {
      color: v.$beige-light;
    }
  }
  &__copy {
    color: v.$primary;
    text-align: center;
  }
}
