@use '../../foundation/variables' as v;
@use 'sass:list';

$colors: hsla(48, 90%, 49%), #676767, v.$primary-light, v.$primary, v.$primary-dark;

.p-ranking {
  margin-bottom: 3em;
  @include v.sp {
    display: none;
  }
  &__item {
    width: 100%;
    margin-bottom: 2em;
    @each $color in $colors {
      $i: list.index($colors, $color);
      &:nth-child(#{$i}) {
        .p-ranking__thumb {
          &::before {
            content: '0#{$i}';
            background-color: rgba($color: $color, $alpha: 0.9);
          }
        }
      }
    }
    @include v.sp {
      margin-bottom: 2em;
    }
  }
  &__detail {
    margin: 0.5em 0;
  }
  &__text {
    font-weight: bold;
    font-size: v.$scale-down1;
    margin-top: 0.1em;
    @include v.three-point-reader();
    @include v.sp {
      font-size: v.$scale-up6
    }
  }
  &__place {
    font-size: v.$scale-down2;
    @include v.sp {
      font-size: v.$scale-down1;
    }
  }
  &__category,
  &__time {
    font-size: v.$scale-down2;
    @include v.sp {
      font-size: v.$scale-down1;
    }
  }
  &__thumb {
    position: relative;
    &::before {
      @include v.belleza;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      font-size: v.$scale-up5;
      color: v.$beige-light;
      display: block;
      padding: 0.5em 0.75em;
      line-height: 1;
      @include v.flexbox(center, center, nowrap);
    }
  }
  .c-sidebar-header {
    text-transform: uppercase;
  }
}
