@use '../../foundation/variables' as v;

.c-top-h2 {
  h2 {
    @include v.belleza;
    font-size: v.$scale-up2;
    text-transform: uppercase;
    >span {
      @include v.default-font;
      line-height: 1;
    }
  }
  p {
    @include v.letter-space;
    display: block;
    font-size: v.$scale-down1;
    font-weight: bold;
    color: v.$primary;
    margin-left: 1em;
    @include v.sp {
      margin-left: 0;
    }
  }
}

.c-post {
  &__thumb {
    overflow: hidden;
    border-radius: 4px;
    background-color: v.$beige;
    img {
      @include v.animation(transform);
    }
  }
  @media (hover: hover) {
    &:hover {
      > .c-post__thumb {
        img {
          transform: scale(1.05, 1.05);
        }
      }
    }
  }
  &__detail {
    @include v.flexbox(between, center, nowrap);
  }
  &__place {
    color: v.$primary;
    font-weight: bold;
    margin-bottom: 0;
  }
  &__text {
    font-weight: bold;
  }
}

.c-meta-info {
  @include v.flexbox(start, center, nowrap);
  svg {
    color: v.$primary;
    margin-right: 0.25em;
    width: 18px;
    height: 18px;
    @include v.animation(color);
  }
}

.c-category {
  background-color: v.$primary-light;
  color: v.$beige-light;
  padding: 0.25em 1em;
  border-radius: 50px;
  letter-spacing: 0.01em;
}

.c-time {
  color: v.$primary;
}

.c-button {
  position: relative;
  display: block;
  padding: 1em 0;
  border: 1px solid v.$primary-dark;
  text-align: center;
  border-radius: 50px;
  text-decoration: none;
}

.c-shop {
  padding: 0.75em 2em;
  border: 1px solid rgba($color: v.$primary, $alpha: 0.7);
  width: 220px;
  height: 50px;
  @include v.flexbox(center, center, nowrap);
  svg {
    color: v.$beige-light;
    margin-right: 0.5em;
    width: 20px;
    height: 20px;
    @include v.animation(color);
  }
}

.c-logo {
  @include v.flexbox(start, center, nowrap);
  flex-flow: column;
  @include v.belleza;
  font-size: v.$scale-up1;
  position: relative;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  font-feature-settings: unset;
  @include v.sp {
    font-size: v.$scale-up2;
  }
  span {
    font-size: 0.3em;
    display: block;
    @include v.default-font;
  }
}

.c-sidebar-header {
  background-color: v.$primary;
  color: v.$beige-light;
  @include v.belleza;
  padding: 0.75em 1em;
  border-radius: 5px;
  margin-bottom: 1em;
}

.c-search {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba($color: v.$primary-light, $alpha: 0.7);
  @include v.flexbox(center, center, nowrap);
  @include v.button-hover(50px, 50px, v.$primary-light);
  @include v.animation(border);
  @media (hover: hover) {
    &:hover {
      border: 1px solid v.$primary-light;
      svg {
        color: v.$beige-light;
      }
    }
  }
  @include v.sp {
    display: none;
  }
  &__content {
    z-index: 1;
    svg {
      width: 18px;
      height: 18px;
      color: v.$primary-light;
      @include v.animation(color);
    }
  }
}

.c-menu-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba($color: v.$primary-dark, $alpha: 0.7);
  @include v.flexbox(center, center, nowrap);
  @include v.button-hover(50px, 50px, v.$primary-dark);
  @include v.animation(border);
  @media (hover: hover) {
    &:hover {
      border: 1px solid v.$primary-dark;
      svg {
        color: v.$beige-light;
      }
    }
  }
  @include v.sp {
    width: 42px;
    height: 42px;
  }
  &__content {
    z-index: 1;
    svg {
      width: 18px;
      height: 18px;
      color: v.$primary-dark;
      @include v.animation(color);
    }
  }
}

.c-close {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba($color: v.$beige-light, $alpha: 0.7);
  @include v.flexbox(center, center, nowrap);
  @include v.button-hover(50px, 50px, v.$beige-light);
  @media (hover: hover) {
    &:hover {
      svg {
        color: v.$primary-dark;
      }
    }
  }
  @include v.sp {
    width: 42px;
    height: 42px;
  }
  &__content {
    z-index: 1;
    svg {
      width: 18px;
      height: 18px;
      color: v.$beige-light;
      @include v.animation(color);
    }
  }
}

.c-caution {
  border: 1px solid v.$red;
  padding: 1.5em;
  color: v.$red;
  @include v.sp {
    padding: 1.5em;
  }
}

.c-tag {
  margin: 0.3em;
  a {
    letter-spacing: .01em;
    font-size: v.$scale-down1;
    background-color: rgba($color: v.$primary, $alpha: 0.1);
    border-radius: 4px;
    color: v.$primary-dark;
    display: inline-block;
    padding: 0.25em 1em;
    @include v.animation();
    &:hover {
      background-color: v.$primary-dark;
      color: v.$beige-light;
    }
  }
}

.c-breadcrumbs {
  a, span {
    font-size: v.$scale-down1;
  }
  span {
    color: v.$primary;
  }
  a {
    @include v.animation(color);
    @media(hover: hover) {
      &:hover {
        color: v.$primary;
      }
    }
  }
}

.c-loading {
  width: 100%;
  height: 100%;
  background-color: v.$primary-dark;
  position: fixed;
  z-index: map-get(v.$z, loading);
  @include v.flexbox(center, center, nowrap);
  visibility: visible;
  opacity: 1;
  span {
    width: 0.75em;
    height: 0.75em;
    background-color: rgba(255, 248, 235, 0.7);
    border-radius: 50%;
    display: block;
    margin: 0 0.5em;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-name: bubble;
    animation-iteration-count: infinite;
    &:nth-of-type(2) {
      animation-delay: -0.33s;
    }
    &:nth-of-type(3) {
      animation-delay: -0.66s;
    }
  }
}

@keyframes bubble {
  0% {
    opacity: 0;
    transform: translateY(300%);
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-300%);
  }
}

.c-searchbox {
  width: 100%;
  @include v.flexbox(center, center, nowrap);
  max-width: 850px;
  margin: 0 auto;
  input {
    font-size: 18px;
    padding: 0.5em 0.75em;
    width: 100%;
    height: 70px;
    margin-right: 0.5em;
    border-radius: 8px;
    border: none;
    background-color: v.$beige-light;
    border: 1px solid v.$primary-light;
    @include v.sp {
      font-size: 16px;
    }
  }
  button {
    background-color: v.$primary-light;
    color: v.$beige;
    width: 120px;
    height: 70px;
    border-radius: 8px;
  }
}

