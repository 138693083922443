@use '../../foundation/variables' as v;

.p-recommend {
  background-color: v.$beige;
  &__title {
    @include v.flexbox(start, start, no-wrap);
    h3 {
      font-size: v.$scale-up2;
      text-transform: uppercase;
      @include v.belleza();
    }
    p {
      letter-spacing: 0.025em;
      display: block;
      font-size: 0.889rem;
      font-weight: bold;
      color: v.$primary;
      margin-left: 1em;
      @include v.sp {
        margin-left: 0;
      }
    }
  }
  &__title {
    @include v.flexbox(start, center, no-wrap);
    margin-bottom: 2.5em;
    @include v.sp {
      flex-flow: column;
      align-items: flex-start;
    }
  }
  &__list {
    @include v.flexbox(between, start, wrap);
    @include v.tb {
      flex-flow: column;
      justify-content: flex-start;
    }
  }
  &__item {
    width: 32%;
    margin-bottom: 2em;
    @include v.tb {
      width: 100%;
    }
  }
  &__detail {
    margin: 0.75em 0 0.5em;
  }
  &__text {
    font-weight: bold;
    font-size: v.$scale-up6;
    margin-top: 0.1em;
    @include v.three-point-reader();
  }
  &__place {
    font-size: v.$scale-down1;
    font-weight: bold;
  }
  &__category,
  &__time {
    font-size: v.$scale-down1;
  }
}