@use '../../foundation/variables' as v;

.p-blog {
  &__title {
    @include v.flexbox(start, center, no-wrap);
    margin-bottom: 2.5em;
    @include v.sp {
      flex-flow: column;
      align-items: flex-start;
    }
  }
  &__toggle {
    display: none;
    margin-bottom: 2em;
    @include v.tb {
      @include v.flexbox(between, center, no-wrap);
    }
    &__tab {
      @include v.belleza;
      color: v.$primary;
      border: 1px solid v.$primary;
      border-radius: 5px;
      font-size: v.$scale-up5;
      @include v.flexbox(center, center, no-wrap);
      width: 48%;
      height: 56px;
      line-height: 1.2;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      @include v.animation();
      &--active {
        background-color: v.$primary;
        color: v.$beige-light;
      }
    }
  }
  &__list {
    @include v.flexbox(between, start, wrap);
    @include v.tb {
      flex-flow: column;
      justify-content: flex-start;
    }
  }
  &__item {
    width: 48%;
    margin-bottom: 2em;
    @include v.tb {
      width: 100%;
    }
    &:nth-child(n + 6) {
      @include v.tb {
        display: none;
      }
    }
  }
  &__button {
    margin: 2em auto;
    width: 100%;
    max-width: 350px;
    height: 65px;
    @include v.flexbox(center, center, nowrap);
    @include v.button-hover(350px, 65px, v.$primary-dark);
    @include v.animation(color);
    @media(hover: hover) {
      &:hover {
        color: v.$beige-light;
      }
    }
  }
  &__button-content {
    z-index: 1;
  }
  &__detail {
    margin: 0.75em 0 0.5em;
  }
  &__text {
    font-weight: bold;
    font-size: v.$scale-up6;
    margin-top: 0.1em;
    @include v.three-point-reader();
  }
  &__place {
    font-size: v.$scale-down1;
    font-weight: bold;
  }
  &__category,
  &__time {
    font-size: v.$scale-down1;
  }
}

.p-toggle-list {
  display: none;
  &--active {
    display: flex;
    @include v.flexbox(between, start, wrap);
    @include v.tb {
      flex-flow: column;
      justify-content: flex-start;
    }
  }
}